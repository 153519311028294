// Set up preact context

import { createContext } from 'preact';
import { useContext } from 'preact/hooks';

import type { SetState } from '../../../types';

type ConsentSpinnerContextType = {
    isSpinFinal : boolean,
    setIsSpinFinal : SetState<boolean>,
};

export const ConsentSpinnerContext = createContext<ConsentSpinnerContextType | undefined>(undefined);

export const useConsentSpinnerContext = () : ConsentSpinnerContextType => {
    const context = useContext(ConsentSpinnerContext);

    if (!context) {
        throw new Error('Can not find consent spinner context');
    }

    return context;
};
