import type { CallOneTextAPIResult } from './api';

import { callOneTextAPI } from './api';

import type { PostShopifyViewProductAPI } from '@onetext/api';

export const sendShopifyProductViewWebhook = (
    body : PostShopifyViewProductAPI['request']
) : Promise<CallOneTextAPIResult<PostShopifyViewProductAPI['response']>> => {
    return callOneTextAPI({
        path: 'shopify/webhook/client/product/view',
        body
    });
};
