import { associate } from '../../api';
import { LOCALSTORAGE_KEY, QUERY_PARAM } from '../../constants';
import { userIdentifiedEvent } from '../../event';
import { debouncePromise, isLocalStorageEnabled, promiseTry } from '../../lib';

import type { Email, PhoneNumber, TOKEN, TokenType } from '@onetext/api';

export const getStoredAccountToken = () : TokenType<TOKEN.ACCOUNT> | undefined => {
    const queryString = new URLSearchParams(window.location.search);

    const accountToken = (
        queryString.get(QUERY_PARAM.ONETEXT_ACCOUNT_TOKEN) ??
        queryString.get(QUERY_PARAM.ONETEXT_ACCOUNT_TOKEN_LEGACY) ??
        (
            isLocalStorageEnabled()
                ? localStorage.getItem(LOCALSTORAGE_KEY.ONETEXT_ACCOUNT_TOKEN)
                : undefined
        )
    ) ?? undefined;

    return accountToken as TokenType<TOKEN.ACCOUNT> | undefined;
};

export const isCustomerRecognized = () : boolean => {
    return Boolean(getStoredAccountToken());
};

export const storeAccountLocally = (accountToken : string) : void => {
    const localStorageEnabled = isLocalStorageEnabled();

    if (accountToken && localStorageEnabled) {
        localStorage.setItem(LOCALSTORAGE_KEY.ONETEXT_ACCOUNT_TOKEN, accountToken);
    }
};

type Customer = {
    recognized : boolean,
};

export const getCustomer = () : Promise<Customer> => {
    return promiseTry(() => {
        const recognized = isCustomerRecognized();

        return {
            recognized
        };
    });
};

type CustomerIdentity = {
    klaviyoID ?: string,
    accountToken ?: TokenType<TOKEN.ACCOUNT>,
    phone ?: PhoneNumber,
    email ?: Email,
    cartToken ?: string,
};

const identifierState : CustomerIdentity = {};

export const associateCustomer = debouncePromise(() => {
    return associate(identifierState).then(({
        body: { accountToken }
    }) => {
        if (accountToken) {
            identifierState.accountToken = accountToken;
            storeAccountLocally(accountToken);
            userIdentifiedEvent.emit({ accountToken });
        }

        return {
            accountToken
        };
    });
}, 1000);

type IdentifyCustomerResponse = {
    accountToken : TokenType<TOKEN.ACCOUNT> | undefined,
};

export const identifyCustomer = ({
    klaviyoID,
    accountToken,
    phone,
    email,
    cartToken
} : CustomerIdentity) : Promise<IdentifyCustomerResponse> => {
    return promiseTry(() => {
        let newIdentifiers = false;

        if (accountToken && accountToken !== identifierState.accountToken) {
            identifierState.accountToken = accountToken;
            newIdentifiers = true;
        }

        if (klaviyoID && klaviyoID !== identifierState.klaviyoID) {
            identifierState.klaviyoID = klaviyoID;
            newIdentifiers = true;
        }

        if (phone && phone !== identifierState.phone) {
            identifierState.phone = phone;
            newIdentifiers = true;
        }

        if (email && email !== identifierState.email) {
            identifierState.email = email;
            newIdentifiers = true;
        }

        if (cartToken && cartToken !== identifierState.cartToken) {
            identifierState.cartToken = cartToken;
            newIdentifiers = true;
        }

        if (newIdentifiers) {
            return associateCustomer();
        } else {
            return {
                accountToken: getStoredAccountToken()
            };
        }
    });
};

export const initializeCustomer = async () : Promise<void> => {
    const accountToken = getStoredAccountToken();

    if (accountToken) {
        await identifyCustomer({
            accountToken
        });
    }
};
