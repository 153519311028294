import { QUERY_PARAM, SDK_ATTRIBUTE, SDK_QUERY } from '../constants';

import { getURLQueryParam } from './dom';
import { delay, getCurrentScript, tokenToEnv } from './util';

import { type ENV, type Milliseconds, NODE_ENV } from '@onetext/api';

export const isDevelopment = () : boolean => {
    return process.env.NODE_ENV === NODE_ENV.DEVELOPMENT;
};

export const getSDKScript = () : HTMLScriptElement => {
    return getCurrentScript();
};

export const getSDKUrl = () : string => {
    return getSDKScript().src;
};

export const getAPIDomain = () : string => {
    const script = getSDKScript();
    const domain = script.getAttribute(SDK_ATTRIBUTE.API_DOMAIN);

    if (domain) {
        return domain;
    }

    const url = new URL(script.src);
    return `${ url.protocol }//${ url.host }`;
};

export const getSDKAttribute = (name : string) : string | undefined => {
    const value = getSDKScript().getAttribute(name);
    return value ?? undefined;
};

export const getSDKScriptQueryParams = () : URLSearchParams => {
    const scriptSrc = getSDKAttribute('src');

    if (!scriptSrc) {
        return new URLSearchParams();
    }

    let url : URL;

    if ((/^https?:\/\//).test(scriptSrc)) {
        url = new URL(scriptSrc);
    } else if (isDevelopment() && scriptSrc.startsWith('/')) {
        url = new URL(scriptSrc, window.location.origin);
    } else {
        return new URLSearchParams();
    }

    return url.searchParams;
};

export const getSDKQueryParam = (name : string) : string | undefined => {
    const queryParams = getSDKScriptQueryParams();
    return queryParams.get(name) ?? undefined;
};

export const getSDKAccountToken = () : string | undefined => {
    return getSDKAttribute(SDK_ATTRIBUTE.ACCOUNT_TOKEN) ?? getSDKQueryParam(SDK_QUERY.ACCOUNT_TOKEN);
};

export const getSDKClientID = () : string | undefined => {
    return getSDKAttribute(SDK_ATTRIBUTE.CLIENT_ID) ?? getSDKQueryParam(SDK_QUERY.CLIENT_ID);
};

export const getSDKEnv = () : ENV => {
    const sdkEnv = getSDKAttribute(SDK_ATTRIBUTE.ENV);

    if (sdkEnv) {
        return sdkEnv as ENV;
    }

    const sdkAccountToken = getSDKAccountToken();

    if (sdkAccountToken) {
        return tokenToEnv(sdkAccountToken);
    }

    const sdkClientID = getSDKClientID();

    if (sdkClientID) {
        return tokenToEnv(sdkClientID);
    }

    throw new Error(`Can not determine SDK environment`);
};

type SDKScriptData = {
    src : string,
    attributes : Record<string, string | undefined>,
};

export const getSDKScriptData = () : SDKScriptData => {
    return {
        src:        getSDKUrl(),
        attributes: {
            [SDK_ATTRIBUTE.ACCOUNT_TOKEN]: getSDKAccountToken(),
            [SDK_ATTRIBUTE.CLIENT_ID]:     getSDKClientID()
        }
    };
};

export const isPreviewMode = () : boolean => {
    return Boolean(
        isDevelopment() ||
        getURLQueryParam(QUERY_PARAM.PREVIEW)
    );
};

export const getSDKAuthToken = () : string | undefined => {
    const username = getSDKAccountToken() ?? getSDKClientID();

    if (!username) {
        return;
    }

    return btoa(`${ username }:`);
};

export const getSDKAuthHeader = () : string | undefined => {
    const authToken = getSDKAuthToken();

    if (!authToken) {
        return;
    }

    return `Basic ${ authToken }`;
};

let currentScriptModifiedAt : string | undefined;

export const hotReloadPageOnSDKUpdate = () : Promise<void> => {
    const script = getSDKUrl();

    return fetch(script).then(res => {
        const lastModified = res.headers.get('Last-Modified') ?? undefined;
        currentScriptModifiedAt ??= lastModified;

        if (lastModified) {
            if (lastModified === currentScriptModifiedAt) {
                return delay(1000 as Milliseconds).then(hotReloadPageOnSDKUpdate);
            } else {
                window.location.reload();
            }
        }
    });
};

export const triggerOneTextLoad = () : void => {
    const existingOneTextLoad = window.onLoadOneText ??= [];

    const load = (...loaders : ReadonlyArray<OneTextLoader>) : number => {
        loaders.forEach(loader => {
            setTimeout(() => {
                void loader();
            }, 0);
        });

        return loaders.length;
    };

    existingOneTextLoad.push = load;

    load(...existingOneTextLoad);
};
