/** @jsx h */

import type { ComponentChildren, VNode } from 'preact';

import { h } from 'preact';
import { useCallback, useMemo, useState } from 'preact/hooks';

import type { ConsentFormContextType, FormField } from '../context';

import { promiseTry } from '../../../lib';
import { ConsentFormContext } from '../context';

type ConsentPopupFormProps = {
    children ?: ComponentChildren,
    onSubmit : () => Promise<void> | void,
};

export const ConsentPopupForm = ({
    children,
    onSubmit
} : ConsentPopupFormProps) : VNode => {
    const [ displayValidity, setDisplayValidity ] = useState(false);
    const [ fields, setFields ] = useState<Set<FormField>>(new Set());

    const submit = useCallback(() : Promise<void> => {
        return promiseTry(() => {
            for (const field of fields) {
                if (!field.isValid()) {
                    setDisplayValidity(true);
                    return;
                }
            }

            return onSubmit();
        });
    }, [ fields, setDisplayValidity, onSubmit ]);

    const registerField = useCallback((field : FormField) => {
        setFields(existingFields => {
            existingFields.add(field);
            return existingFields;
        });

        return {
            unregister: () => {
                setFields(existingFields => {
                    existingFields.delete(field);
                    return existingFields;
                });
            }
        };
    }, [ setFields ]);

    const context : ConsentFormContextType = useMemo(() => {
        return {
            submit,
            registerField,
            displayValidity
        };
    }, [ submit, registerField, displayValidity ]);

    return (
        <ConsentFormContext.Provider value={ context }>
            { children }
        </ConsentFormContext.Provider>
    );
};
