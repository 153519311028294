/** @jsx h */

import type { VNode } from 'preact';

import clsx from 'clsx';
import { h } from 'preact';

type ConsentPopupTermsProps = {
    termsUrl : string,
    privacyUrl : string,
};

export const ConsentPopupTerms = ({
    termsUrl,
    privacyUrl
} : ConsentPopupTermsProps) : VNode => {
    return (
        <p className={
            clsx(
                'text-[9px] md:text-[10px]',
                'font-light',
                'leading-relaxed',
                'text-center',
                'w-[300px] md:w-[320px]',
                'opacity-80'
            )
        }>
            {
                'By entering your phone number, you agree to receive ' +
                'occasional automated marketing texts from us ' +
                '(or on our behalf). ' +
                'No purchase required. ' +
                'Reply HELP for help; ' +
                'STOP to opt out. View '
            }
            <a
                href={ termsUrl }
                target={ '_blank' }
                rel={ 'noopener noreferrer' }
                className={ 'underline' }>{ 'Terms' }
            </a>
            { ' & ' }
            <a
                href={ privacyUrl }
                target={ '_blank' }
                rel={ 'noopener noreferrer' }
                className={ 'underline' }>{ 'Privacy' }
            </a>
        </p>
    );
};
