// Set up preact context for brand

import { createContext } from 'preact';
import { useContext } from 'preact/hooks';

import type { BrandConfig } from '../../../types';

type BrandContextType = {
    brandConfig ?: BrandConfig,
};

export const BrandContext = createContext<BrandContextType | undefined>(undefined);

export const useBrandContext = () : BrandContextType => {
    const context = useContext(BrandContext);

    if (!context) {
        throw new Error('Can not find brand context');
    }

    return context;
};
