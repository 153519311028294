import { identifyCustomer } from '../biz';
import { QUERY_PARAM, SELECTOR } from '../constants';
import { listenForPhoneInput } from '../lib';

const associateAccountFromKlaviyoID = async () : Promise<void> => {
    const queryString = new URLSearchParams(window.location.search);
    const klaviyoID = queryString.get(QUERY_PARAM.KLAVIYO_IDENTIFIER);

    if (klaviyoID) {
        await identifyCustomer({
            klaviyoID
        });
    }
};

const listenForKlaviyoPhoneInput = () : void => {
    listenForPhoneInput({
        selector: SELECTOR.KLAVIYO_INPUT,
        handler:  ({ phone }) => {
            void identifyCustomer({
                phone
            });
        }
    });
};

export const initializeKlaviyoIntegration = async () : Promise<void> => {
    listenForKlaviyoPhoneInput();
    await associateAccountFromKlaviyoID();
};
