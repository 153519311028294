/* @flow */

import { callOneTextAPI, type CallOneTextAPIResult } from './api';

import type { PostShopifyBrowseAPI } from '@onetext/api';

export const sendShopifyBrowseWebhook = (
    body : PostShopifyBrowseAPI['request']
) : Promise<CallOneTextAPIResult<PostShopifyBrowseAPI['response']>> => {
    return callOneTextAPI({
        path: 'shopify/webhook/client/browse',
        body
    });
};
