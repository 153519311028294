import { fetchShopifyCart, sendShopifyBrowseWebhook, sendShopifyProductViewWebhook } from '../api';
import { identifyCustomer } from '../biz';
import { userIdentifiedEvent } from '../event';
import { assertExists, promiseTry } from '../lib';

export enum SHOPIFY_PAGE_TYPE {
    PRODUCT = 'product',
    CART = 'cart',
    PAGE = 'page'
}

const getShopName = () : string | undefined => {
    return window.Shopify?.shop;
};

const getShopifyPageType = () : SHOPIFY_PAGE_TYPE | undefined => {
    const pageType = window.meta?.page?.pageType as SHOPIFY_PAGE_TYPE | undefined;

    if (pageType) {
        return pageType;
    }

    if ((/^\/products\/[^/?]+/).test(location.pathname)) {
        return SHOPIFY_PAGE_TYPE.PRODUCT;
    }

    if (location.pathname.indexOf('/cart') === 0) {
        return SHOPIFY_PAGE_TYPE.CART;
    }
};

const getSelectedVariantID = () : string | undefined => {
    const variants = window.meta?.product?.variants ?? [];

    if (variants.length === 1) {
        return assertExists(variants[0]).id.toString();
    }

    const inputElement = document.querySelector('[name=id]') as HTMLInputElement | undefined;

    return inputElement?.value.toString();
};

const getSelectedVariantQuantity = () : number => {

    const inputElement = document.querySelector('[name=quantity]') as HTMLInputElement | undefined;

    const quantity = inputElement?.value ?? '1';
    const integerRegex = /^\d+$/;

    if (integerRegex.test(quantity)) {
        return Number.parseInt(quantity, 10);
    }

    return 1;
};

const getSelectedVariantName = () : string | undefined => {
    const variantID = getSelectedVariantID();
    return window.meta?.product?.variants?.find(variant => variant.id.toString() === variantID)?.name;
};

const getSelectedProductID = () : string | undefined => {
    const productCandidate = (
        window.meta?.product?.id?.toString() ??
        window.product?.id?.toString()
    );

    const integerRegex = /^\d+$/;

    if (productCandidate && integerRegex.test(productCandidate)) {
        return productCandidate;
    }
};

export const isShopifyStore = () : boolean => {
    return Boolean(window.Shopify);
};

export type ShopifyPageData = {
    shopName : string,
    pageType : SHOPIFY_PAGE_TYPE,
    productID ?: string,
    items : ReadonlyArray<{
        variantID : string,
        variantName ?: string,
        quantity : number,
    }>,
};

const getShopifyPageData = () : Promise<ShopifyPageData | undefined> => {
    return Promise.resolve().then(() => {
        const shopName = getShopName();
        const pageType = getShopifyPageType();
        const selectedVariantID = getSelectedVariantID();
        const selectedVariantQuantity = getSelectedVariantQuantity();
        const selectedProductID = getSelectedProductID();
        const selectedVariantName = getSelectedVariantName();

        if (!isShopifyStore() || !shopName || !pageType) {
            return;
        }

        if (selectedProductID && (
            pageType === SHOPIFY_PAGE_TYPE.PRODUCT ||
            pageType === SHOPIFY_PAGE_TYPE.PAGE
        )) {
            return {
                shopName,
                pageType,
                productID: selectedProductID,
                items:     selectedVariantID
                    ? [
                        {
                            variantID:   selectedVariantID,
                            variantName: selectedVariantName ?? undefined,
                            quantity:    selectedVariantQuantity
                        }
                    ]
                    : []
            };
        }

        if (pageType === SHOPIFY_PAGE_TYPE.CART) {
            return fetch('/cart.js').then(res => {
                return res.json();
            }).then((body : unknown) => {
                const json = body as {
                    items : ReadonlyArray<{
                        variant_id : string,
                        quantity : number,
                    }>,
                };

                if (!json.items.length) {
                    return;
                }

                return {
                    shopName,
                    pageType: SHOPIFY_PAGE_TYPE.CART,
                    items:    json.items.map(item => {
                        return {
                            variantID: item.variant_id.toString(),
                            quantity:  item.quantity
                        };
                    })
                };
            });
        }
    });
};

type TrackShopifyBrowseAndProductViewOptions = {
    accountToken : string,
};

let sentProductRecovery = false;
let sentBrowseRecovery = false;

const trackShopifyBrowseAndProductView = ({
    accountToken
} : TrackShopifyBrowseAndProductViewOptions) : Promise<void> => {
    return promiseTry(() => {
        if (!isShopifyStore()) {
            return;
        }

        const pageType = getShopifyPageType();

        if (!pageType) {
            return;
        }

        const isEligibleProductPage = (
            pageType === SHOPIFY_PAGE_TYPE.PRODUCT ||
            pageType === SHOPIFY_PAGE_TYPE.PAGE
        );

        const canSendBrowseRecovery = (
            !sentBrowseRecovery &&
            !sentProductRecovery
        );

        return getShopifyPageData().then((pageData) => {
            if (!pageData) {
                return;
            }

            if (isEligibleProductPage && pageData.productID && !sentProductRecovery) {
                return sendShopifyProductViewWebhook({
                    accountToken,
                    productID: pageData.productID
                }).then(() => {
                    sentProductRecovery = true;
                });
            } else if (Math.random() < 0.2 && canSendBrowseRecovery) {
                return sendShopifyBrowseWebhook({
                    accountToken
                }).then(() => {
                    sentBrowseRecovery = true;
                });
            }
        });
    });
};

export const initializeShopifyIntegration = async () : Promise<void> => {
    if (isShopifyStore()) {
        userIdentifiedEvent.listen(trackShopifyBrowseAndProductView);

        const cartToken = await fetchShopifyCart();

        if (cartToken) {
            await identifyCustomer({
                cartToken
            });
        }
    }
};
