import type { CallOneTextAPIResult } from './api';

import { callOneTextAPI } from './api';

import type { PostOptInAPI } from '@onetext/api';

export const submitOptIn = (
    body : PostOptInAPI['request']
) : Promise<CallOneTextAPIResult<PostOptInAPI['response']>> => {
    return callOneTextAPI({
        path: 'optin',
        body
    });
};
