import type { JSX } from 'preact';

export type BrandFont = {
    family ?: {
        primary ?: string,
        secondary ?: string,
    },
};

export enum BUTTON_SIZE {
    XS = 'xs',
    SM = 'sm',
    MD = 'md',
    LG = 'lg',
    XL = 'xl'
}

export enum BUTTON_LEVEL {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary'
}

export enum BUTTON_ATTRIBUTE {
    TEXT = 'text',
    BACKGROUND = 'background',
    BORDER = 'border'
}

export enum BUTTON_STATE {
    BASE = 'base',
    HOVER = 'hover'
}

export type ButtonColors = {
    [level in BUTTON_LEVEL] ?: {
        [attribute in BUTTON_ATTRIBUTE] ?: {
            [state in BUTTON_STATE] ?: string
        }
    }
};

export type FieldStyle = JSX.CSSProperties;
export type ButtonStyle = JSX.CSSProperties;

export type BrandColors = {
    text ?: string,
    background ?: string,
};

export type BrandConfig = {
    font ?: BrandFont,
    colors ?: BrandColors,
    field ?: {
        style ?: FieldStyle,
    },
    button ?: {
        style ?: ButtonStyle,
        colors ?: ButtonColors,
    },
};
