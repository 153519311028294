/** @jsx h */
import type { ComponentChildren, VNode } from 'preact';

import { h } from 'preact';
import { useCallback, useEffect, useMemo, useState } from 'preact/hooks';
import { twMerge as tw } from 'tailwind-merge';

import { openKeyboard } from '../../../lib';
import { ConsentPopupPageContext, ConsentSpinnerContext, useConsentPopupContext } from '../context';

import { ConsentPopupForm } from './form';

let globalPageID = 0;

type ConsentPopupPageProps = {
    children : ComponentChildren,
    className ?: string,
    openKeyboardOnSubmit ?: boolean,
};

export const ConsentPopupPage = ({
    children,
    className = undefined,
    openKeyboardOnSubmit = false
} : ConsentPopupPageProps) : VNode | null => {
    const [ pageID ] = useState(() => {
        globalPageID += 1;
        return globalPageID;
    });

    const {
        activePageID,
        registerPage,
        submitPage
    } = useConsentPopupContext();

    useEffect(() => {
        return registerPage(pageID);
    }, [ pageID, registerPage ]);

    if (activePageID !== pageID) {
        return null;
    }

    const submit = useCallback(() => {
        if (openKeyboardOnSubmit) {
            openKeyboard();
        }

        return submitPage();
    }, [ submitPage, openKeyboardOnSubmit ]);

    const [ isSpinFinal, setIsSpinFinal ] = useState<boolean>(false);

    const pageContext = useMemo(() => {
        return {
            submit
        };
    }, [ submitPage ]);

    const spinnerContext = useMemo(() => {
        return {
            isSpinFinal,
            setIsSpinFinal
        };
    }, [ isSpinFinal, setIsSpinFinal ]);

    return (
        <ConsentPopupPageContext.Provider value={ pageContext }>
            <ConsentSpinnerContext.Provider value={ spinnerContext }>
                <ConsentPopupForm onSubmit={ submit }>
                    <div className={ tw('relative w-full h-full pb-32 md:pb-16', className) }>
                        { children }
                    </div>
                </ConsentPopupForm>
            </ConsentSpinnerContext.Provider>
        </ConsentPopupPageContext.Provider>
    );
};
