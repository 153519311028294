/** @jsx h */
import type { VNode } from 'preact';

import { h, render as preactRender } from 'preact';

type RenderComponentOptions<
    Props extends Record<string, unknown>
> = {
    component : (props : Props) => VNode | null,
    props : Props,
    container : string | HTMLElement,
    shadowDOM ?: boolean,
    newElement ?: boolean,
};

export const renderComponent = <
    Props extends Record<string, unknown>
>({
    component: Component,
    props,
    container,
    shadowDOM = false,
    newElement = false
} : RenderComponentOptions<Props>) : void => {
    let finalContainer : HTMLElement;

    if (typeof container === 'string') {
        const containerCandidate = document.querySelector(container) as HTMLElement | undefined;

        if (!containerCandidate) {
            throw new Error(`Can not find container`);
        }

        finalContainer = containerCandidate;
    } else {
        finalContainer = container;
    }

    if (newElement) {
        const element = document.createElement('div');
        finalContainer.appendChild(element);
        finalContainer = element;
    }

    const element = shadowDOM
        ? finalContainer.attachShadow({
            mode: 'closed'
        })
        : finalContainer;
    preactRender(h(Component, props), element);
};

type SimpleExternalComponentOptions<Props> = {
    component : (props : Props) => VNode,
    shadowDOM ?: boolean,
};
export type SimpleExternalComponent<Props> = (props : Props) => {
    render : (container : string | HTMLElement) => void,
};

export const simpleExternalComponent = <
    Props extends Record<string, unknown>
>({
    component,
    shadowDOM = false
} : SimpleExternalComponentOptions<Props>) : SimpleExternalComponent<Props> => {
    const initialize = (props : Props) : ReturnType<SimpleExternalComponent<Props>> => {
        const render = (container : string | HTMLElement) : void => {
            renderComponent({
                component,
                props,
                container,
                shadowDOM
            });
        };

        return {
            render
        };
    };

    return initialize;
};
