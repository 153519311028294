// Set up preact context

import { createContext } from 'preact';
import { useContext } from 'preact/hooks';

type ConsentPopupPageContextType = {
    submit : () => Promise<void> | void,
};

export const ConsentPopupPageContext = createContext<ConsentPopupPageContextType | undefined>(undefined);

export const useConsentPopupPageContext = () : ConsentPopupPageContextType => {
    const context = useContext(ConsentPopupPageContext);

    if (!context) {
        throw new Error('Can not find consent popup page context');
    }

    return context;
};
