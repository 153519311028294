import { initializeCustomer } from './biz';
import { initializeIntegrations } from './integrations';
import { hotReloadPageOnSDKUpdate, isDevelopment, promiseTry, triggerOneTextLoad } from './lib';

export * from './index';

void promiseTry(async () => {
    await Promise.all([
        initializeIntegrations(),
        initializeCustomer(),
        isDevelopment()
            ? hotReloadPageOnSDKUpdate()
            : undefined,
        triggerOneTextLoad()
    ]);
});
