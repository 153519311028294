import { getSDKAccountToken, getSDKClientID } from '../lib';

import type { CallOneTextAPIResult } from './api';

import { callOneTextAPI, constructOneTextURL, redirectInPopup } from './api';

import type { PostMessagesThreadSwitchAPI } from '@onetext/api';

export const getSwitchToSMSThreadLink = (
    body : PostMessagesThreadSwitchAPI['request'] = {}
) : Promise<CallOneTextAPIResult<PostMessagesThreadSwitchAPI['response']>> => {
    return callOneTextAPI({
        path: 'messages/thread/switch',
        body
    });
};

type GetBasicSwitchToSMSThreadLinkOptions = {
    message ?: string,
};

export const getBasicSwitchToSMSThreadLink = ({
    message
} : GetBasicSwitchToSMSThreadLinkOptions = {}) : string => {
    const accountToken = getSDKAccountToken();
    const clientID = getSDKClientID();

    if (!accountToken && !clientID) {
        throw new Error(`Can not determine clientID`);
    }

    const query : Record<string, string> = {};

    if (message) {
        query.message = message;
    }

    if (accountToken) {
        query.accountToken = accountToken;
    }

    if (clientID) {
        query.clientID = clientID;
    }

    return constructOneTextURL({
        path: 'sms/switch',
        query
    });
};

type SwitchToSMSThreadOptions = {
    message ?: string,
};

export const switchToSMSThread = ({
    message
} : SwitchToSMSThreadOptions = {}) : Promise<void> => {
    return redirectInPopup({
        url: getBasicSwitchToSMSThreadLink({
            message
        })
    });
};
