type ShopifyCart = {
    token ?: string,
};

export const fetchShopifyCart = async () : Promise<string | undefined> => {
    return await fetch(`${ window.Shopify?.routes?.root ?? '/' }cart.js`, {
        method:  'POST',
        headers: {
            'Accept':       'application/json',
            'Content-Type': 'application/json'
        }
    }).then(res => {
        return res.json();
    }).then((json) => {
        const cart = json as ShopifyCart;

        return cart.token;
    });
};
